import React, { useState, useRef  } from 'react';
import './assets/sass/Home.scss';
import Header from './theme/Header';
import Footer from './theme/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import emailjs from '@emailjs/browser';


function Home() {
  const [success, setSucsess] = useState('');

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_k458cfx', 'template_63sfsho', form.current, {
        publicKey: 'pb3_4299wq3spCtp9',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSucsess('Thank you for your submission! We appreciate your effort and will get back to you shortly.');
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          
        },
      );
  };




  
  return (
    <div className="App" id="Home">
     
        <div className="banner">
            <Container className='text-center'>
              <h3 className='gradient-text'>One stop solution for all your business needs</h3>
             
              <a href="https://wa.me/9882985247?text=Hi" target='_blank' rel="noreferrer" className='btn-consult mt-4'>Consult now <img src={require('./assets/img/arrow-up.png')} alt="consult"/></a>
            </Container>
        </div>
        {/*
        <div className='trusted-by' id='partners'>
        <Container className='text-center'>
           <h3 className='gradient-text'>Trusted by Leading Organizations</h3>
           <div className='mt-4 equal-spc'>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/guvi.png')} className='img-fluid' alt="consult"/></div>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/hawmi.png')} className='img-fluid' alt="consult"/></div>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/farmididi.png')} className='img-fluid' alt="consult"/></div>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/knmn.png')} className='img-fluid' alt="consult"/></div>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/real-property.png')} className='img-fluid' alt="consult"/></div>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/goodness.jpeg')} className='img-fluid' alt="Goodness Hospital"/></div>
              <div  className='d-flex justify-content-center align-items-center'><img src={require('./assets/img/bright.jpeg')} className='img-fluid' alt="Bright Avenue School"/></div>
           </div>
          </Container>
        </div>
      */}
        <div className='ideas-into-digital' >
        <Container className='text-center' id='services'>
          <h3 className='gradient-text font-weight-light mb-5'>Transforming Ideas into Digital Reality: Explore Our Services.</h3>
            <Row className='mt-4'>
              <Col  md={3}  className='align-items-center trans-col'>
                <div>
                <img src={require('./assets/img/web-developer.png')} height={44} alt="consult"/>
                <h4>Web Development</h4>
                <p>offers end-to-end website development services, crafting visually stunning and highly functional websites tailored to your business goals.</p>
                </div>
              </Col>
              <Col  md={3}  className='align-items-center trans-col'>
              <div>
              <img src={require('./assets/img/mobile.png')} height={44}  alt="consult"/>
                <h4>Mobile Apps</h4>
                <p>Our team creates high-performance, user-friendly mobile applications tailored to your business goals for iOS and Android platforms</p>
                </div>
              </Col>
              <Col md={3}  className='align-items-center trans-col'>
              <div>
                <img src={require('./assets/img/content-creation.png')} height={44}  alt="consult"/>
                <h4>UI/UX Design                </h4>
                <p>Our UI/UX design services blend creativity with usability, ensuring your digital products are both visually appealing and easy to navigate.</p>
                </div>
              </Col>
              <Col md={3} className='align-items-center trans-col'>
              <div>
              <img src={require('./assets/img/seo.png')} height={44}  alt="consult"/>
                <h4>Digital Marketing</h4>
                <p>We specialize in SEO, social media, content marketing, and PPC campaigns to drive traffic, rease engagement, and grow your brand.</p>
                </div>
              </Col>
             
            
            </Row>


        </Container>
        </div>


        <div className='sets-us-apart'>
        <Container className='text-center'>
          <h3 className='gradient-text font-weight-light'>What sets us apart</h3>
            <Row className="mt-5">
              
              <Col md={{ span: 4, offset: 1 }}  className='align-items-center trans-col'>
                
                <img src={require('./assets/img/innovative.png')} alt="consult"/>
                <h4>Innovative Solutions</h4>
                <p>Fueling innovation in every service, we craft solutions that redefine norms, offering customers groundbreaking experiences that stand as a testament to our ingenuity.</p>
              </Col>

              <Col  md={{ span: 4, offset: 2 }} className='align-items-center trans-col'>
             
                <img src={require('./assets/img/expert-team.png')} alt="consult"/>
                <h4>Expert Team</h4>

                <p>With a diverse blend of expertise and a shared passion for excellence, our team at Intactcode consistently delivers unparalleled service, setting a gold standard in customer satisfaction. </p>
              </Col>
              </Row>
              </Container>
              </div>


      <div className='customers-visit'>
        <Container className='text-center'>
            <Row>
              {/** 
              <Col md={4}  className='align-items-center'>
                
                <h3 className='gradient-text font-weight-light'>1M+</h3>
                <p>Customers visit Intactcode every month to get their service done.</p>
              </Col>
*/}
              <Col  md={6}className='align-items-center'>
             
                <h3 className='gradient-text font-weight-light'>96%</h3>
                <p>Satisfaction rate comes from our awesome customers.</p>
              </Col>
              <Col  md={6}className='align-items-center'>
             
            
             <h3 className='gradient-text font-weight-light'>4.9/5.0</h3>
             <p>Unlock Your Potential with a Free First Consultation!</p>
           </Col>
              </Row>
              </Container>
              </div>

              <div className='about-us' id='about'>
                <Container>
                  <Row className='align-items-center'>
                    <Col md={6}  className='align-items-center'>
                    <h3 className='gradient-text font-weight-light px-3'>About us</h3>
                    <p className='px-3'> 
                    At Intactcode, we specialize in delivering innovative IT solutions tailored to meet the unique needs of your business. Our mission is to ensure your technology infrastructure operates seamlessly and efficiently, allowing you to focus on what matters most—growing your business.</p>
                    <p className='px-3'> 
Our team of skilled professionals is committed to leveraging the latest technology trends and best practices to provide solutions that are not only effective but also scalable and future-proof.

                    </p>
                    </Col>
                    <Col md={6}  className='align-items-center '>
                      <img  src={require('./assets/img/about.png')} className='img-fluid' alt="About us"/>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className='contact-form'  id='contact'>
                  <Container>
                  <div className='align-items-center trans-col'>
                    <Row>
                   
              
                        <Col md={5}  className='align-items-center'>
                        <img  src={require('./assets/img/chat.png')} className='img-fluid' alt="About us"/>
                            <h3 className='gradient-text'>We look forward to helping you</h3>
                            <p>Please, send us a message or give us a call. We are looking forward to hearing from you and would like to know, how we can help you.</p>
                        </Col>
                        <Col md={{ span: 6, offset: 1 }} >
                        
                        <form ref={form} onSubmit={sendEmail}>
                        <Form.Label htmlFor="basic-url">Name</Form.Label>
                        <InputGroup  className="mb-3">
                            <InputGroup.Text id="basic-addon1"><img  src={require('./assets/img/form-name.png')} className='img-fluid' alt="About us"/></InputGroup.Text>
                              <Form.Control
                                placeholder="Name"
                                required
                                aria-label="name"
                                aria-describedby="basic-addon1"
                                name="name"
                               
                              />
                          </InputGroup>
                          <Form.Label htmlFor="basic-url">Email</Form.Label>
                        <InputGroup  className="mb-3">
                            <InputGroup.Text id="basic-addon1"><img  src={require('./assets/img/form-email.png')} className='img-fluid' alt="About us"/></InputGroup.Text>
                              <Form.Control
                                placeholder="Email"
                                required
                                type='email'
                                aria-label="email"
                                aria-describedby="basic-addon1"
                                name="email"
                              />
                          </InputGroup>
                          <Form.Label htmlFor="basic-url">Mobile</Form.Label>
                        <InputGroup  className="mb-3">
                            <InputGroup.Text id="basic-addon1"><img  src={require('./assets/img/cell-phone.png')} className='img-fluid' alt="About us"/></InputGroup.Text>
                              <Form.Control
                              type="number"
                                placeholder="Mobile"
                                required
                                aria-label="Mobile"
                                aria-describedby="basic-addon1"
                                name="number"
                              />
                          </InputGroup>

                          <Form.Label htmlFor="basic-url">Message</Form.Label>
                        <InputGroup  className="mb-3">
                          
                              <Form.Control
                                placeholder="Message"
                                aria-label="message"
                                required
                                as="textarea" 
                                aria-describedby="basic-addon1"
                                name="message"
                              />
                          </InputGroup>
                          <Form.Check // prettier-ignore
                              type={'checkbox'}
                              id={`i-agree`}
                              required
                              name="i_agree"
                              value={'I agree to Privacy Policy and Terms of Use'}
                              label={`I agree to Privacy Policy and Terms of Use`}
                             
                            />
                             <div className="d-grid mt-3 gap-2">
                             <Button variant="primary" type='submit' size="lg">Contact with us now</Button>
                             </div>
                             {success ? success : null }
                             </form>
                        </Col>
                        
                      </Row>
                      </div>
                  </Container>

              </div>

              <div className='testimonials'>
                <Container>
                <h3 className='gradient-text font-weight-light'  id="testimonials">What they say about us</h3>
                    <Row>
                   
                      <Col  md={4}  className='align-items-center trans-col'>
                      <div className='col-wrap'>

                              <div className="d-flex gap-3">
                                {/* <div> <img src={require('./assets/img/Nugraha.png')} alt="consult" width="50" className="rounded-circle"/></div> */}
                                <div className="ml-2">
                                  <span className="name">Manoj Aggarwal</span>
                                    <p className="para">Owner of HNG</p>
                                </div>
                            </div>
                            <span className="maintxt">As a small business owner, I was overwhelmed with the complexities of managing our IT
infrastructure. The team at <b>Intactcode </b> made the entire process seamless and
worry-free. Their expertise in app development and prompt support have been invaluable.
Thanks to them, I can now focus on growing my business without worrying about technical
issues.</span>                            
                           
                      </div>
                      </Col>
                      <Col  md={4}  className='align-items-center trans-col'>
                      <div className='col-wrap'>

                              <div className="d-flex gap-3">
                              
                                <div className="ml-2">
                                  <span className="name">Satpal Malik</span>
                                    <p className="para">Operations Manager at Technical Gurus . </p>
                                </div>
                            </div>
                            <span className="maintxt">Switching to <b>Intactcode </b> for our social media marketing has been one of the best
decisions we’ve made. They quickly understood our needs and provided solutions that
enhanced our operational efficiency. Their round-the-clock support ensures we never
experience downtime. Highly recommended!</span>                            
                           
                      </div>
                      </Col>
                      <Col  md={4}  className='align-items-center trans-col'>
                      <div className='col-wrap'>

                              <div className="d-flex gap-3">
                           
                                <div className="ml-2">
                                  <span className="name">Jessica Morgan</span>
                                    <p className="para">CTO at HealthLink Services</p>
                                </div>
                            </div>
                            <span className="maintxt">The professionalism and dedication of <b>Intactcode </b> are unparalleled. They took the time
to understand our unique challenges and delivered customized software solutions that
significantly improved our workflow. Their proactive approach to maintenance and support
has been a game-changer for us.</span>                            
                           
                      </div>
                      </Col>
                      <Col  md={4}  className='align-items-center trans-col'>
                      <div className='col-wrap'>

                              <div className="d-flex gap-3">
                           
                                <div className="ml-2">
                                  <span className="name">Mark Johnson</span>
                                    <p className="para">CEO of Innovatech Solutions</p>
                                </div>
                            </div>
                            <span className="maintxt">The professionalism and dedication of <b>Intactcode </b> are unparalleled. They took the time
to understand our unique challenges and delivered customized software solutions that
significantly improved our workflow. Their proactive approach to maintenance and support
has been a game-changer for us.</span>                            
                           
                      </div>
                      </Col>
                      <Col  md={4}  className='align-items-center trans-col'>
                      <div className='col-wrap'>

                              <div className="d-flex gap-3">
                            
                                <div className="ml-2">
                                  <span className="name">Emily Rogers</span>
                                    <p className="para">Director of Marketing at BrightWave Media</p>
                                </div>
                            </div>
                            <span className="maintxt">We partnered with <b>Intactcode </b> during a critical phase of our company’s growth. Their
team integrated seamlessly with our operations and provided digital accessible services like
ERP solutions that scaled us. Their expertise in content creation and animations gave us
peace of mind, knowing our growth is always stable.</span>                            
                           
                      </div>
                      </Col>
                      <Col  md={4}  className='align-items-center trans-col'>
                      <div className='col-wrap'>

                              <div className="d-flex gap-3">
                        
                                <div className="ml-2">
                                  <span className="name">Michael Brown</span>
                                    <p className="para">CFO at FutureTech Enterprises</p>
                                </div>
                            </div>
                            <span className="maintxt">The team at <b>Intactcode </b> is outstanding! They have a deep understanding of the latest
AI tools and how to apply them to real-world business problems. Their innovative solutions
have helped us streamline our processes and improve productivity. They have set a new
standard of excellence in our business.</span>                            
                           
                      </div>
                      </Col>
                    </Row>
                </Container>
              </div>
 

    </div>
  );
}

export default Home;
